.account-section {
	h1,
	h2,
	h3 {
		margin: 4px 0;
		padding: 0;
	}

	h1 {
		text-transform: uppercase;
		font-size: 18px;
		line-height: 0.9em;
		padding-bottom: 4px;
		margin: 0;
		margin-bottom: 4px;
		border-bottom: 4px solid #cccccc;
	}

	p {
		margin: 4px 0;
	}

	td p,
	tbody th p {
		margin: 0;
		line-height: 1.2em;
	}

	em.detail {
		font-size: 0.85em;
	}

	td,
	tbody th {
		vertical-align: top;
	}

	&.right {
		text-align: right;
		background-color: #f6f6f6;
		box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
		padding: 12px;
		height: 100%;
		h1 {
			text-align: right;
		}
		p,
		h4 {
			line-height: 1.2em;
		}
	}
}
