.wrapper-class {
	padding: 2px;
	border: 1px solid #ccc;
}

.editor-class {
	background-color: #eee;
	color: #444;
	padding: 1rem;
	border: 1px solid #ccc;
	min-height: 24em;
	max-height: 24em;
	overflow-y: auto;

	div,
	p,
	li,
	span {
		font-weight: inherit;
	}

	div,
	p,
	li,
	span,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	h7 {
		line-height: 1em;
	}

	b,
	strong {
		font-size: 3em;
		font-weight: 800;
		color: #ff0000;
	}
}

.toolbar-class {
	border: 1px solid #ccc;

	li,
	span {
		color: #000;
	}
}
