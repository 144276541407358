@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500;700&display=swap');

div,
p,
li,
span {
	font-weight: 500;
}

strong {
	font-weight: 700;
}

body {
	margin: 0;
	/*
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  */
	font-family: 'Quicksand', 'Helvetica-Neue', 'Arial', 'sans-serif';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body * {
	box-sizing: border-box;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
