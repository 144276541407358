body {
	line-height: 1em;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	min-height: 8vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: calc(10px + 0.5vmin);
	padding-bottom: calc(10px + 0.5vmin);
	font-size: calc(10px + 2vmin);
	margin: 0 auto;
	border: 4px solid #ddd;
	border-top: 0;
	border-bottom-left-radius: 5vw;
	border-bottom-right-radius: 5vw;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
	/*
	background-color: #666;
	*/
	width: 50%;
	@media (max-width: 600px) {
		width: 95%;
	}
}

.CALCULATED_PROPERTIES_EXAMPLE_RELATIVE_CHANGING_FONT_SIZE {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

/*
strong {
	color: #f4222f;
}
*/

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
